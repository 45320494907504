import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import ContactPerson from "../../../components/contactPerson";
import { GatsbyImage } from "gatsby-plugin-image";
import Hero from "../../../components/hero";
import { CaseList } from "../../../components/caseList.en";

import doodle from "../../../assets/images/services/backend.svg";

const BackendInfrastructure = () => {
  const images = useStaticQuery(graphql`
    {
      hero: file(relativePath: { eq: "services/hero-backend.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      dirk: file(relativePath: { eq: "profiles/dirk-schroedter.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  `);

  return (
    <Layout lang="en" langPath="service.backend.link">
      <Seo
        title="Backend and infrastructure – Monday Consulting"
        description="At Monday Consulting, we focus on developing new and advanced infrastructures. Our experts know how to deploy leading technologies to give you an advanced and high-performance architecture that is also easy to maintain."
        keywords="Backend, architecture, infrastructure, Kubernetes, Java, Spring, Docker, container, microservices, DevOps, development"
      />
      <div className="parallax-translate bg-shape-vertigo"></div>
      <Hero
        headline="Behind the scenes – but ahead of the game"
        subline="Get to where you need to be faster with the ultramodern technologies on offer from our experts."
        section="Backend & infrastructure"
      >
        <GatsbyImage
          image={images.hero.childImageSharp.gatsbyImageData}
          className="hero-image"
          alt="Backend & infrastructure"
        />
      </Hero>
      <div className="container mt-3 mb-5 mb-md-6">
        <div className="row align-items-start">
          <div className="col-md-6 d-none d-sm-block">
            <img
              className="w-75"
              alt="Future-proof infrastructure"
              src={doodle}
            />
          </div>
          <div className="col-md-6">
            <h2>Future-proof infrastructure</h2>
            <p>
              With innovation cycles becoming shorter and customer needs more
              demanding, an advanced infrastructure is becoming the mainstay of
              a company’s overall digital competivenes. From our backend
              developers to our DevOps engineers, the experts at Monday
              Consulting are seasoned users of all of the latest technical
              advances. Our goal is to design a modern and high-performance
              architecture that is also easy to maintain. To achieve this, we
              focus on developing container architectures and closely integrated
              microservices. Our development work also prioritizes performance,
              and the creation of clean and well-structured code – to ensure
              applications are stable, snappy and easy to maintain down the
              road.
            </p>
            <p>
              We use Docker and Kubernetes to keep our solutions as flexible and
              as scalable as possible. Operating isolated from their
              environment, containers not only give your website failover
              protection but also cut down on resource use. Container
              architectures are also ideal for use with microservices, which in
              turn offer you a whole host of additional benefits. Each
              microservice is developed to be autonomous and standalone, with a
              clearly defined interface. This approach lets us shorten our
              development cycles while rolling out deployments and updates to
              you even sooner. The capability (or failure) of one service does
              not affect its neighbor modules, giving the overall system far
              greater stability than in a monolithic setup.
            </p>
          </div>
        </div>
      </div>

      <div className="container mb-md-0">
        <div
          className="row vertical-spacing mb-3 reveal"
          data-spacing-bottom="details"
        >
          <h2 className="col-12 text-center">
            Backend & infrastructure in detail
          </h2>
        </div>
        <div className="row align-items-start">
          <div
            className="reveal col-11 col-md-5 mb-4 mb-md-6 bg-serviceicon-java bg-position-tl bg-size-50 vertical-offset"
            id="details"
            data-offset="50%"
          >
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Java & Spring
            </h3>
            <p>
              As a broad-based ecosystem, Java is the foundation for many
              enterprise solutions, and a technology that is both
              well-established and up-to-date. The software platform quickly
              incorporates recent trends, so as to future-proof current
              development projects, and both the language and its development
              processes are mature and business-oriented.
            </p>
          </div>

          <div className="reveal col-11 col-md-5 offset-1 pb-4 pb-md-0 mb-4 mb-md-6 bg-serviceicon-kubernetes bg-position-tl bg-size-50">
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Kubernetes, Docker & DevOps
            </h3>
            <p>
              Creating software artifacts as container images – with Docker, for
              example – makes them quick and easy to deploy at any stage in the
              software lifecycle, from development to production use. Kubernetes
              handles environment provisioning, artifact deployment and the
              demand-based scaling of the required level of performance. All of
              this ensures modern DevOps methods are used to their fullest
              advantage.
            </p>
          </div>

          <div
            className="reveal col-11 col-md-5 mb-4 mb-md-6 offset-md-1 bg-serviceicon-microservices bg-position-tl bg-size-50 vertical-offset"
            data-offset="50%"
          >
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Microservices
            </h3>
            <p>
              Developing features as separate services means we can stay more
              agile and flexible during the development project. Systems also
              become easier to maintain and use as a starting-point for
              optimization work. Individual services can also be scaled
              independently of one another, which maximizes performance while
              increasing the resilience of your overall system.
            </p>
          </div>

          <div className="reveal col-11 col-md-5 pb-4 pb-md-0 mb-4 offset-1 bg-serviceicon-api bg-position-tl bg-size-50">
            <h3 className="h4">
              <span className="countable text-size-0 text-color-monday-skyblue">
                &nbsp;
              </span>
              Third-party systems & interfaces
            </h3>
            <p>
              We develop bespoke interfaces (e.g. with GraphQL) to ensure you
              can access data efficiently and securely from any of your
              company’s third-party systems. This maintains compliance with
              security models (DMZ) and means that the data is only sent to an
              application if explicitly requested.
            </p>
          </div>
        </div>
      </div>

      <div
        className="content-container vertical-spacing bg-monday-skyblue bg-pattern-vertigo text-color-white text-center parallax-background"
        data-spacing-bottom="cases"
      >
        <div className="container pt-4 pt-md-5 pb-3 reveal">
          <div className="row mb-3">
            <div className="col-12 d-flex flex-column align-items-center">
              <h3 className="mb-3">Selected case studies</h3>
            </div>
          </div>
        </div>
      </div>
      <div
        id="cases"
        className="container vertical-offset reveal mb-md-6 mb-5"
        data-offset="50%"
      >
        <CaseList name="hansemerkur,generali" />
      </div>

      <ContactPerson
        headline="How can we help you today?"
        text="After browsing our impressive portfolio of services, why not contact us to find out more? Advice and assistance is just a phone call away."
        contactEmail="business@monday-consulting.com"
        contactPhone="+49 40 22 86 842 0"
        contactName="Dirk Schrödter, Chief Executive"
      >
        <GatsbyImage
          image={images.dirk.childImageSharp.gatsbyImageData}
          alt="Dirk Schrödter"
        />
      </ContactPerson>
    </Layout>
  );
};

export default BackendInfrastructure;
